<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="PR Requisition List"
                   @onClickAddNewButton="onClickAddNewButton"/>

      <RequisitionSearchInfo @onClickGo="getPrAllocations"/>

    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/procurement/requisition/pending/ListingTable'
import Pagination from '@/components/atom/Pagination'
import RequisitionSearchInfo from "@/components/molecule/procurement/requisition/RequisitionSearchInfo";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()

function onClickAddNewButton() {
  router.push({name: `pr-allocation-add`, params: route.params, query: route.query});
}

function getPrAllocations() {
  //todo
}

function onPageChange() {
  //todo
}

</script>
